@import './react-tags-autocomplete.scss';

html {
  scroll-behavior: smooth;
  font-size: 1.2rem;
  font-weight: 300;
}

.slick-slider {
  .slick-dots {
    bottom: -45px;
  }
}

.spinner {
  animation: spin infinite 1s linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

p {
  margin: 0;
}

pre {
  padding: 10px;
  background-color: #eee;
  white-space: pre-wrap;
}

:not(pre) > code {
  font-family: monospace;
  background: #ececec;
  padding: 0 10px;
  border-radius: 2px;
}

img {
  max-width: 100%;
  max-height: 20em;
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

blockquote[dir='rtl'] {
  border-left: none;
  padding-left: 0;
  padding-right: 10px;
  border-right: 2px solid #ddd;
}

iframe {
  width: 100%;
  border: 1px solid #eee;
}

.slate-editor__toolbar {
  display: flex;
  align-items: center;
  background-color: rgb(41, 41, 41);
  z-index: 1000;
  margin-bottom: 5px;
  span {
    padding: 5px 10px;
  }
}

@media only screen and (max-width: 800px) {
  .slate-editor__toolbar {
    span {
      padding: 5px;
    }
  }
}

a[data-slate-node='element'] {
  color: inherit;
}

.slate-editor__sidebar {
  padding: '8px 7px 6px';
  position: 'absolute';
  z-index: 1000000;
  top: '-10000px';
  left: '-10000px';
  margin-top: '-6px';
  opacity: 0;
  // background-color: '#E5E5E5';
  border-radius: '4px';
  transition: 'opacity 0.75s';
  box-shadow: '0px 2px 2px 0 rgba(0, 0, 0, 0.09)';
  border: '1px solid rgba(0, 0, 0, 0.09)';
  & > * {
    display: 'inline-block';
  }
  & > * + * {
    margin-left: '15px';
  }
  // background-color: red;
}
